import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { setupListeners } from '@reduxjs/toolkit/query'

// Reducers
import settingsReducer from './settingsSlice'
import { netizenApi } from '@/services/netizen'

// Middleware
import { rtkQueryReduxMiddleware } from '@/redux/middlewares/retryMiddleware'

// ---------------------------------------------------------------------------------

const settingsPersistConfig = {
   key: 'netizen_settings',
   storage: storage,
}

const reducers = {
   settings: persistReducer(settingsPersistConfig, settingsReducer),
   [netizenApi.reducerPath]: netizenApi.reducer,
}

// ---------------------------------------------------------------------------------

export const store = configureStore({
   reducer: reducers,
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
         immutableCheck: false, // Disable ImmutableStateInvariantMiddleware
         serializableCheck: false, // Optionally disable SerializableStateInvariantMiddleware if needed
      }).concat(
         (store) =>
            rtkQueryReduxMiddleware(store, { maxRetries: 3, closeOnRetryLimitReached: true }),
         netizenApi.middleware
      ),
   devTools: process.env.NODE_ENV !== 'production',
})

setupListeners(store.dispatch)