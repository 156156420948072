import { isRejectedWithValue } from '@reduxjs/toolkit'
import { toast } from '@/utils/helpers/toast'

export const rtkQueryReduxMiddleware =
   (store, { maxRetries = 3, closeOnRetryLimitReached = true }) =>
   (next) =>
   (action) => {
      if (isRejectedWithValue(action)) {
         const { meta } = action
         const { retryCount = 0 } = action.meta

         if (retryCount < maxRetries && closeOnRetryLimitReached) {
            const errorMessage =
               action.payload.data.message || 'Something went wrong, please try again.'

            toast.error(errorMessage)

            const retryAction = {
               ...action,
               meta: {
                  ...meta,
                  /**
                   * If the status is 500, increment the retryCount by 1
                   * Otherwise, set the retryCount to 3 so that the retry limit is reached
                   */
                  retryCount: action.payload.status === 500 ? retryCount + 1 : 3,
               },
            }
            store.dispatch(retryAction)

            if (action.payload.status === 500) {
               return next(retryAction)
            }

            return
         }

         return
      }

      return next(action)
   }
