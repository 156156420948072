import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import Head from 'next/head'
import { persistStore } from 'redux-persist'
import { store } from '@/redux/store'
import createEmotionCache from '../createEmotionCache'
import { CacheProvider } from '@emotion/react'
import '@rainbow-me/rainbowkit/styles.css'
import { darkTheme, getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { createConfig, WagmiProvider } from 'wagmi'
import { mainnet, polygon, sepolia } from '@wagmi/core/chains'
import { http } from 'viem'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../styles/global.css'
import '../styles/transaction-modal.css'
import '../styles/contracts.css'
import { RainbowKitSiweNextAuthProvider } from '@rainbow-me/rainbowkit-siwe-next-auth'
import { SessionProvider } from 'next-auth/react'
import { ThirdwebProvider } from '@thirdweb-dev/react'
import { magicEdenWallet } from '../utils/helpers/magicEdenConnector'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import '../styles/color-picker.css'
import '../styles/create-profile-cards.css';
import '../styles/price-tier-card.css'; 
import '../styles/freelance-card.css';
import '../styles/nft-traits.css'
import '../styles/home.css'
import '../styles/freelance-preview-card.css';
import '../styles/preview-price-tiers.css';
import '../styles/feature-selection.css';
import '../styles/preview-box-themes.css';
import '../styles/portfolio.css';
import '../styles/price-v2.css';
import '../styles/company-themes-preview-box.css';
import '../styles/Recommendation.css';
import { DefaultSeo } from 'next-seo'

const persistor = persistStore(store)
const clientSideEmotionCache = createEmotionCache()
const chains = [mainnet, polygon, sepolia]

const { connectors } = getDefaultWallets({
  appName: 'Web3Me',
  projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
  chains,
  wallets: [
    {
      groupName: 'Recommended',
      wallets: [magicEdenWallet],
    },
  ],
})
const wagmiClient = createConfig({
   connectors,
   chains,
   transports: {
      [mainnet.id]: http(),
      [polygon.id]: http(),
      [sepolia.id]: http(),
   },
})

const getSiweMessageOptions = () => ({
   statement: 'Sign in to Web3Me',
})

const queryClient = new QueryClient()

export default function App({ Component, pageProps, emotionCache = clientSideEmotionCache }) {
   return (
      <>
         <Head>
            <title>Secure Your Web3 Identity: Display & Verify Your Crypto Portfolio Across Wallets</title>
            <meta name="google-site-verification" content="9bmo-85lkf1okI4iLQGWaMtdYVtE2ypcE40aeWShWMg" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
            <link rel="icon" href="/favicon.ico" />
            <meta name="description" content="Showcase your verified Web3 identity and crypto portfolio seamlessly. Web3Me connects and verifies multiple wallets, creating a trustworthy decentralized profile." />
            <meta name="keywords" content="Web3 identity, Crypto portfolio display, Verify crypto wallets, Web3 profile, Blockchain identity verification, Web3 wallet connection, Cryptocurrency identity, Multi-wallet verification, Decentralized identity" />
         </Head>

         <DefaultSeo
            title="Secure Your Web3 Identity: Display & Verify Your Crypto Portfolio Across Wallets"
            description="Showcase your verified Web3 identity and crypto portfolio seamlessly. Web3Me connects and verifies multiple wallets, creating a trustworthy decentralized profile."
            openGraph={{
               type: 'website',
               locale: 'en_US',
               url: 'https://www.web3me.com/',
               site_name: 'Web3Me',
               images: [
                  {
                     url: 'https://www.web3me.com/images/og-image.png',
                     width: 800,
                     height: 600,
                     alt: 'Web3Me',
                  },
               ],
            }}
            twitter={{
               handle: '@web3me',
               site: '@web3me',
               cardType: 'summary_large_image',
            }}
         />

         <CacheProvider value={emotionCache}>
            <ThirdwebProvider activeChain="polygon" clientId={'07d32bb75847ca1a930994d47b2c6233'}>
               <SessionProvider refetchInterval={0} session={pageProps.session}>
                  <QueryClientProvider client={queryClient}>
                     <WagmiProvider config={wagmiClient}>
                        <RainbowKitSiweNextAuthProvider getSiweMessageOptions={getSiweMessageOptions}>
                           <RainbowKitProvider
                              coolMode
                              chains={chains}
                              theme={darkTheme({
                                 accentColor: '#00f7f0',
                                 borderRadius: 'none',
                                 accentColorForeground: 'black',
                                 fontStack: 'system',
                              })}
                           >
                              <PersistGate loading={null} persistor={persistor}>
                                 <Provider store={store}>
                                    <ToastContainer />
                                    <Component {...pageProps} />
                                 </Provider>
                              </PersistGate>
                           </RainbowKitProvider>
                        </RainbowKitSiweNextAuthProvider>
                     </WagmiProvider>
                  </QueryClientProvider>
               </SessionProvider>
            </ThirdwebProvider>
         </CacheProvider>
         
      </>
   )
}