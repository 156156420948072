import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   wallets: {},
   selectedWallet: null,
   createCvData: {
      canvasItems: [],
      showActivity: false,
      selectedInsights: [],
      selectedTokens: [],
      selectedNfts: [],
      pdf: '',
   },
}

export const settingsSlice = createSlice({
   name: 'settings',
   initialState,
   reducers: {
      updateSettings: (state, action) => {
         return {
            ...state,
            ...action.payload,
         }
      },
   },
})

export const { updateSettings } = settingsSlice.actions

export default settingsSlice.reducer
