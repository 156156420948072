import { toast as reactToastify } from 'react-toastify'

const globalToastProps = {
   style: {
      fontSize: 13,
   },
   position: 'top-right',
   autoClose: 3500,
   hideProgressBar: false,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true,
   theme: 'dark',
}

export const toast = {
   success: (title, options) => {
      reactToastify.success(title, {
         ...globalToastProps,
         ...options,
      })
   },
   error: (title, options) => {
      reactToastify.error(title, {
         ...globalToastProps,
         ...options,
      })
   },
   warning: (title, options) => {
      reactToastify.warning(title, {
         ...globalToastProps,
         ...options,
      })
   },
   info: (title, options) => {
      reactToastify.info(title, {
         ...globalToastProps,
         ...options,
      })
   },
}
