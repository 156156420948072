import {
   buildCreateApi,
   coreModule,
   fetchBaseQuery,
   reactHooksModule,
} from '@reduxjs/toolkit/query/react'

const createApi = buildCreateApi(
   coreModule(),
   reactHooksModule({ unstable__sideEffectsInRender: true })
)

export const netizenApi = createApi({
   reducerPath: 'netizenApi',
   baseQuery: fetchBaseQuery({
      baseUrl: '/api',
   }),
   tagTypes: [],
   endpoints: () => ({}),
})
